import { groupEnd } from 'console';
import { ParsedEvent, parsedEventAttrs, CreateEmptyParsedEvent } from '../entities/Event';

// date begin year, date begin month, date begin day
//
const dateBeginEx = /(?<bY>20[0-9]{2}\/|)(?<bM>[1-9]\/|[01][0-9]\/)(?<bD>[1-9]|[0-3][0-9])/;
// date end year, date end month, date end day
// beginとの違いは、monthがoptional。
const dateEndEx = /(?<eY>20[0-9]{2}\/|)(?<eM>[1-9]\/|[01][0-9]\/|)(?<eD>[1-9]|[0-3][0-9])/;
// time begin hour, time begin minute
const timeBeginEx = /(?<bh>[0-9]|[0-2][0-9]):(?<bm>[0-9]|[0-5][0-9])/;
// time end hour, time end minute
const timeEndEx = /(?<eh>[0-9]|[0-2][0-9]):(?<em>[0-9]|[0-5][0-9])/;
const contentEx = /(?<contents>.*)/;
// const parseEventProg = new RegExp(
//     '^' +
//         '(?<date>' +
//         dateBeginEx.source +
//         '(?:|[\\-〜]|[\\-〜]' +
//         dateEndEx.source +
//         '))\\s*' +
//         '(?<time>|' +
//         timeBeginEx.source +
//         '(?:|[\\-〜]|[\\-〜]' +
//         timeEndEx.source +
//         ')' +
//         ')\\s+' +
//         contentEx.source +
//         '$'
// );
const parseEventProg = new RegExp(
    '^' +
        '(?<date>' +
        '(?<bY>20[0-9]{2}/|)(?<bM>[1-9]/|[01][0-9]/)(?<bD>[1-9]|[0-3][0-9])' +
        '(?:|[\\-〜]|[\\-〜]' +
        '(?<eY>20[0-9]{2}/|)(?<eM>[1-9]/|[01][0-9]/|)(?<eD>[1-9]|[0-3][0-9])' +
        '))\\s*' +
        '(?<time>|' +
        // timeBeginEx.source +
        '(?<bh>[0-9]|[0-2][0-9]):(?<bm>[0-9]|[0-5][0-9])' +
        '(?:|[\\-〜]|[\\-〜]' +
        // timeEndEx.source +
        '(?<eh>[0-9]|[0-2][0-9]):(?<em>[0-9]|[0-5][0-9])' +
        ')' +
        ')\\s+' +
        // contentEx.source +
        '(?<contents>.*)' +
        '$'
);
const parseYearSeparatorProg = /^##\s+(?<year>20\d{2})/;

export function parseLine(ss: string): ParsedEvent {
    // https://www.debuggex.com/
    // 文字列リテラルにバックスラッシュを入れるときは、\\を使う。
    // (?:| ) で囲うのはoptionalということ。
    const attrs = parsedEventAttrs;
    const groups = ss.match(parseEventProg)?.groups;
    console.log(ss, groups);
    if (typeof groups !== 'undefined') {
        // 正規表現で書ききれなかった部分
        groups.bY = groups.bY ? groups.bY.replace('/', '') : '';
        groups.eY = groups.eY ? groups.eY.replace('/', '') : '';
        groups.bM = groups.bM ? groups.bM.replace('/', '') : '';
        groups.eM = groups.eM ? groups.eM.replace('/', '') : '';
        for (let i = 0; i < attrs.length; i++) {
            groups[attrs[i]] = groups[attrs[i]] ?? '';
        }
        return groups as ParsedEvent;
    } else {
        const yearSepG = ss.match(parseYearSeparatorProg)?.groups;
        const result = CreateEmptyParsedEvent();
        result.contents = ss;
        if (yearSepG && !isNaN(parseInt(yearSepG.year))) {
            result.bY = yearSepG.year;
            return result;
        } else {
            result.contents = ss;
            return result;
        }
    }
}

export function parse(s: string): Array<ParsedEvent> {
    const arr: Array<string> = s.split(/\r\n|\n/);
    const result = [];
    for (let i = 0; i < arr.length; i++) {
        result.push(parseLine(arr[i]));
    }
    return result;
}

export const __local__ = {
    dateBeginEx,
    timeBeginEx,
    timeEndEx,
    contentEx,
    parseLine,
};

// 最初の予定、または年が変わるタイミングではyearを付けなければいけない。
// year/month/day
// month/day content
// month/day hour:min content
// month/day hour:min-hour:min content
// month/day-day
// month/day,day
// month/day-month/day
// month/day,month/day
// hour:min
// hour:min-hour-min
// http://aa
// https://aa

// さらに拡張記法(順不同)。
// dayは数字かはてな。
// プライベートとかを出し分ける記号。
// !公開
// アラームをかける
// !10
// 10分前アラーム
// !5 !10
// 5分前と10分前にアラーム
