import React, { useState, useEffect } from 'react';
import { Note } from './Note';
import './App.css';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { Setting } from './Setting';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SettingsIcon from '@mui/icons-material/Settings';
import Paper from '@mui/material/Paper';
import { Toaster } from 'react-hot-toast';
import { useLocation, Routes, Navigate, Route } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function App() {
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <div className="App">
            {/* <Snacks snacks={snacks} setSnacks={setSnacks} /> */}
            <Toaster position="top-right" />
            <Routes>
                <Route path="/" element={<Navigate to="/contents/1" replace />} />
                <Route path="/contents/1" element={<Note />} />
                <Route path="/settings" element={<Setting />} />
            </Routes>

            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
                <BottomNavigation
                    showLabels
                    value={location.pathname}
                    onChange={(event, newValue) => {
                        navigate(newValue);
                    }}
                >
                    <BottomNavigationAction label="予定" icon={<CalendarMonthIcon />} value="/contents/1" />
                    <BottomNavigationAction label="設定" icon={<SettingsIcon />} value="/settings" />
                </BottomNavigation>
            </Paper>
        </div>
    );
}

export default App;
