import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { GoogleLoginButton } from './components/Google';

export const Setting = () => {
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ textAlign: 'left', margin: '10px' }}>
                    <p>Googleでログインすると、自動生成byテキストカレンダーという名前でカレンダーが作成され、自動でこのアプリと同期されます。他のカレンダーに影響はありません。</p>
                    <GoogleLoginButton sx={{ float: 'left', textTransform: 'none' }} />
                </Grid>
            </Grid>
        </div>
    );
};
