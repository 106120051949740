export const parsedEventAttrs = ['date', 'bY', 'bM', 'bD', 'eY', 'eM', 'eD', 'time', 'bh', 'bm', 'eh', 'em', 'contents'];

// valid: parseIntできる
// bXとeXのプロパティ(X=Y,M,D,h,m)は、validまたは空文字列であるべき。そうでない場合の動作は未定義。
// GCalにpushできるイベントは、最低限bM, bDがvalidである。他の要素は空文字列でも良い。
// bYがvalidでbM, bDがinvalidなら、yearSeparator (## 2022) として振る舞う。
export type ParsedEvent = {
    // date
    date: string;
    // date begin year, month, day
    bY: string;
    bM: string;
    bD: string;
    // date end
    eY: string;
    eM: string;
    eD: string;
    // time
    time: string;
    // time begin
    bh: string;
    bm: string;
    // time end
    eh: string;
    em: string;
    contents: string;
};

export function IsYearSeparator(pe: ParsedEvent): boolean {
    return !isNaN(parseInt(pe.bY)) && pe.bM === '' && pe.bD === '';
}

export function GetDateFromParsedEvent(pe: ParsedEvent, defaultYear: number): { start: Date; end: Date } {
    const beginYear = pe.bY ? parseInt(pe.bY) : defaultYear;
    const beginMonth = parseInt(pe.bM) - 1;
    const beginDay = parseInt(pe.bD);
    const beginHour = pe.bh ? parseInt(pe.bh) : 0;
    const beginMinutes = pe.bm ? parseInt(pe.bm) : 0;

    const endYear = pe.eY ? parseInt(pe.eY) : defaultYear;
    const endMonth = pe.eM ? parseInt(pe.eM) - 1 : beginMonth;
    const endDay = pe.eD ? parseInt(pe.eD) : beginDay;
    const endHour = pe.eh ? parseInt(pe.eh) : beginHour;
    const endMinutes = pe.em ? parseInt(pe.em) : beginMinutes;

    let start = new Date(beginYear, beginMonth, beginDay, beginHour, beginMinutes);
    let end = new Date(endYear, endMonth, endDay, endHour, endMinutes);
    return { start: start, end: end };
}

// TODO: ParsedEventから時刻を取る関数を書く。

export function CreateEmptyParsedEvent(): ParsedEvent {
    const result: any = {};
    for (let i = 0; i < parsedEventAttrs.length; i++) {
        result[parsedEventAttrs[i]] = '';
    }
    return result;
}

export type GCalDateInput = {
    date: string;
    dateTime: string;
    timeZone: string;
};

export type GCalEventInput = {
    End: GCalDateInput;
    Start: GCalDateInput;
    Summary: string;
    Description: string;
};
