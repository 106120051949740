import { GCalEvent } from '../AUTOGEN_ENTITY';
import { ParsedEvent, GCalEventInput, GCalDateInput, GetDateFromParsedEvent } from '../entities/Event';
import { parseLine } from './Parser';
import toast from 'react-hot-toast';

const AsiaTokyoTZ = 'Asia/Tokyo';
export const AsiaTokyoTimeZoneOffset = 9 * 60 * 60 * 1000;

type DecomposedDateTime = {
    Y: string;
    M: string;
    D: string;
    W: string;
    h: string;
    m: string;
};
function DecomposeDateTime(dt: Date): DecomposedDateTime {
    // Google Calendarでは、Monthは0-11。
    return {
        Y: String(dt.getUTCFullYear()),
        M: String(dt.getUTCMonth() + 1), //0-indexed
        D: String(dt.getUTCDate()),
        W: String(dt.getUTCDay()),
        h: String(dt.getUTCHours()),
        m: String(dt.getUTCMinutes()).padStart(2, '0'),
    };
}

function ConvertToAsiaTokyoDateTime(utcTime: string): Date {
    // UTCで入っているGoogleのTimezoneを、現在のTokyoの時間に変更してくれる。
    // ブラウザがアメリカのtimezoneになった時に面倒。強制的に日本時間にする。
    // JSのDateは、UTCで持っている。それを文字列にするときに、現在の環境のロケールが適用されてしまう。
    // これは、強制的にTokyoの時間にする。
    const dt = new Date(Date.parse(utcTime) + AsiaTokyoTimeZoneOffset);
    // UTCと書いているが、オフセットを足しているので、Asia/Tokyoの時刻。
    return dt;
}

const ONEDAY = 24 * 60 * 60 * 1000;

export function GCalEventToEventText(ge: GCalEvent): string {
    // TODO: テスト書く。
    const raw = ge.Description;
    // const raw = ge.ExtendedProperties?.Private?.Raw;
    let datetime;
    if (raw) {
        // private propertyがあるときの処理
        return raw;
    } else if (ge.Start.Date === '') {
        // private propertyがなく、all day eventでない(ge.Start.Date===''で、ge.Start.Datetimeの方にしか情報がない)ときの処理
        const b = DecomposeDateTime(ConvertToAsiaTokyoDateTime(ge.Start.DateTime));
        const e = DecomposeDateTime(ConvertToAsiaTokyoDateTime(ge.End.DateTime));
        if (b.Y === e.Y && b.M === e.M && b.D === e.D) {
            // dayが同じ場合は一日として書く。
            datetime = `${b.M}/${b.D} ${b.h}:${b.m}-${e.h}:${e.m}`;
        } else if (b.Y === e.Y && b.M === e.M) {
            // 月が同じなら月省略
            datetime = `${b.M}/${b.D}-${e.D} ${b.h}:${b.m}-${e.h}:${e.m}`;
        } else {
            datetime = `${b.M}/${b.D}-${e.M}/${e.D} ${b.h}:${b.m}-${e.h}:${e.m}`;
        }
    } else {
        // all day event のときの処理 (if ge.Start.Date !== '')
        const begin = ge.Start.Date.match(/^(?<Y>20\d{2})-(?<M>[1-9]|[01][0-9])-(?<D>[1-9]|[0-3][0-9])$/)?.groups;
        const b = begin === undefined ? undefined : { Y: parseInt(begin.Y), M: parseInt(begin.M), D: parseInt(begin.D) };
        const endBound = new Date(Date.parse(ge.End.Date) - ONEDAY);
        const e = { Y: endBound.getFullYear(), M: endBound.getMonth() + 1, D: endBound.getDate() }; //Monthは0-indexed
        if (b && b.M && b.D && e.M && e.D) {
            // MとDは少なくともないとだめ。
            // TODO: all day eventのendは、次の日になってしまっている。
            if (b.Y === e.Y && b.M === e.M && b.D === e.D) {
                // dayが同じ場合は省略。
                datetime = `${b.M}/${b.D}`;
            } else if (b.Y === e.Y && b.M === e.M) {
                // 月が同じなら月省略
                datetime = `${b.M}/${b.D}-${e.D}`;
            } else {
                datetime = `${b.M}/${b.D}-${e.M}/${e.D}`;
            }
        } else {
            toast.error('イベントの時刻変換に失敗しました。');
            console.error('Converter.ts Error: cannot parse ge.Start.Date or ge.End.Date');
            return `${ge.Summary}`;
        }
    }
    return `${datetime} ${ge.Summary}`;
}

function DecomposeDateTimeWithPadding(dt: Date): DecomposedDateTime {
    // Google Calendarでは、Monthは0-11。
    // yyyy-mm-ddに合わせるため2桁にする。
    return {
        Y: String(dt.getUTCFullYear()),
        M: String(dt.getUTCMonth() + 1).padStart(2, '0'), //0-indexed
        D: String(dt.getUTCDate()).padStart(2, '0'),
        W: String(dt.getUTCDay()),
        h: String(dt.getUTCHours()),
        m: String(dt.getUTCMinutes()).padStart(2, '0'),
    };
}

function DateToGCalDate(date: Date, isAllDayEvent: boolean): GCalDateInput {
    const sd = DecomposeDateTimeWithPadding(date);
    if (isAllDayEvent) {
        return {
            date: `${sd.Y}-${sd.M}-${sd.D}`,
            dateTime: `0001-01-01T00:00:00Z`,
            timeZone: AsiaTokyoTZ,
        };
    } else {
        return {
            date: ``,
            dateTime: `${sd.Y}-${sd.M}-${sd.D}T${sd.h}:${sd.m}:00Z`,
            timeZone: AsiaTokyoTZ,
        };
    }
}

function ParsedEventToPrivateRaw(pe: ParsedEvent): string {
    return `${pe.date ? pe.date + ' ' : ''}${pe.time ? pe.time + ' ' : ''}${pe.contents}`;
}

export function ParsedeventToGCalEventInput(pe: ParsedEvent, defaultYear: number): GCalEventInput | null {
    // ParsedEventの時刻がValidならGCalEventを返す。Invalidならnullを返す。
    // time begin hourまたはminutesがない、つまり時間指定なし。
    const isAllDayEvent = pe.bh === '' || pe.bm === '';
    let { start, end } = GetDateFromParsedEvent(pe, defaultYear);
    // GCalの入力はUTCで、メモの記入はJSTなので、UTCに変換。
    start = new Date(start.getTime());
    end = new Date(end.getTime());
    if (isAllDayEvent) {
        // all day eventの場合は、終わりは日の終わりに設定する。
        end = new Date(end.getTime() + ONEDAY);
    }

    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
        return null;
    } else {
        const ge: GCalEventInput = {
            Summary: pe.contents,
            Start: DateToGCalDate(start, isAllDayEvent),
            End: DateToGCalDate(end, isAllDayEvent),
            Description: ParsedEventToPrivateRaw(pe),
        };
        console.log(pe, ge, isAllDayEvent);

        return ge;
    }
}

export function ContentsToGCalEvents(contentArr: string[], beginYear: number): Array<GCalEventInput> | null {
    const ges = [];
    let lastValidId = 0;
    for (let i = 0; i < contentArr.length; i++) {
        const pe = parseLine(contentArr[i]);
        // bYがNaNなら、デフォルトのを使う。
        console.log(pe);
        beginYear = parseInt(pe.bY) ? parseInt(pe.bY) : beginYear;
        if (typeof beginYear !== 'undefined') {
            const ge = ParsedeventToGCalEventInput(pe, beginYear);
            if (ge !== null) {
                // 時間がvalidなら、gCalEventsに追加。
                ge.Description = ParsedEventToPrivateRaw(pe);
                lastValidId = i;
                ges.push(ge);
            } else {
                // 時間がinvalidなら、前の有効な要素につける。
                if (ges.length > 0) {
                    const i = ges.length - 1;
                    ges[i].Description = ges[lastValidId].Description + '\n' + pe.contents;
                    ges[i].Summary = ges[i].Summary + '\n' + pe.contents;
                }
            }
        } else {
            console.log('beginYearは、最初の行で指定されていないといけない。このコードパスは通らないはず。', i);
            toast.error('年が指定されていません。');
            return null;
        }
    }
    return ges;
}
