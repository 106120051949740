import { GCalEvent } from '../AUTOGEN_ENTITY';
import { GCalEventInput } from '../entities/Event';
import { GCalEventToEventText } from '../functions/Converter';

export function GCalDiff(before: Array<GCalEvent>, after: Array<GCalEventInput>): { up: Array<GCalEventInput>; down: Array<GCalEvent>; modify: Array<GCalEvent> } | null {
    // 差分検出は、送信直前にやる。
    // 受け取った時にdictを使ってカウント。
    // 現在の全行をカウント。
    // カウントが0のやつは、down、
    // dictにないやつはup。

    // 追加タスク:
    // すべてのdownについて、日付が同じupイベントがあればそれをeditListに追加。。つぎに、全てのdownについて、内容が同じイベントがあればそれをeditListに追加。
    // 残ったdownをremoveListへ、upをaddListへ追加。
    const up: Array<GCalEventInput> = [];
    const down: Array<GCalEvent> = [];
    const modify: Array<GCalEvent> = [];
    const bMap: Map<string, GCalEvent> = new Map();

    for (let i = 0; i < before.length; i++) {
        const b = before[i];
        const raw = GCalEventToEventText(b);
        // 重複イベントを削除
        if (bMap.has(raw)) {
            down.push(b);
        }
        bMap.set(raw, b);
    }
    for (let i = 0; i < after.length; i++) {
        const raw = after[i].Description;
        if (bMap.has(raw)) {
            // 変更されていないということ。相殺の削除。
            bMap.delete(raw);
        } else {
            // 新しく追加されたということ。
            up.push(after[i]);
        }
    }
    bMap.forEach((value, key) => {
        down.push(value);
    });
    console.log('Take diff!', 'up:', up, 'down', down);
    return { up: up, down: down, modify: modify };
}
