import React, { useState, useEffect } from 'react';
import { useWindowDimensions } from './components/Resize';
import { parse, parseLine } from './functions/Parser';
import { stylize } from './functions/Stylizer';
import { GCalEvent } from './AUTOGEN_ENTITY';
import { GCalDiff } from './functions/Diff';
import { ContentsToGCalEvents, AsiaTokyoTimeZoneOffset, GCalEventToEventText } from './functions/Converter';
import Button from '@mui/material/Button';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

function getFromStorage(key: string): string {
    const item = localStorage.getItem(key);
    if (item) {
        return item;
    } else {
        return '';
    }
}

function setToStorage(key: string, item: string) {
    localStorage.setItem(key, item);
}

async function getCalId(): Promise<string> {
    // let calId: string = getFromStorage('/calId/1');
    // if (calId === '') {
    let calId: string;
    let calIdResp: Response;
    try {
        calIdResp = await fetch('/api/calId/1');
    } catch (e) {
        toast.error('カレンダーIDの取得に失敗しました。');
        return '';
    }
    if (calIdResp.status !== 200) {
        return '';
    }
    const data = await calIdResp.json();
    calId = data.id;
    if (calId) {
        console.log('calId/1 set');
        setToStorage('calId/1', calId);
    } else {
        toast.error('カレンダーIDが空です。');
        console.log('getFromServer calId/1 failed');
        return '';
    }
    // }
    return calId;
}

type ContentsSyncResponse = {
    accessRole: string;
    description: string;
    id: string;
    summary: string;
};

type NoteProps = {};

export function Note(props: NoteProps) {
    // TODO: ログインしていないときの挙動
    const { width, height } = useWindowDimensions();
    const navigate = useNavigate();
    // TODO: サーバーとの高速な同期。
    const [content, setContentInternal] = useState('');
    const [calId, setCalId] = useState('');
    // GCalEventToEventTextで変換。送信時にdiffを検知。
    const [gCalEvents, SetGCalEvents] = useState<Array<GCalEvent>>([]);
    const [isInitialized, setInitialized] = useState(false);
    const [isLoggedIn, setLogIn] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [isDirty, setDirty] = useState(false);
    const [saveButtonEnabled, enableSaveButton] = useState(true);

    const fontSize = 16;
    const lineHeight = 1.2;
    const nLines = Math.floor(height / lineHeight / fontSize - 1);

    const initContent = async () => {
        // const s = getFromStorage('/contents/1');
        // こちらはキャッシュ。
        // setContentInternal(s);
        const calId = await getCalId();
        if (calId === '') {
            // on error return
            setInitialized(true);
            return;
        }
        setCalId(calId);
        await syncGCalContents(calId);
    };

    const syncGCalContents = async (calId: string) => {
        let eventsResp: Response;
        try {
            eventsResp = await fetch('/api/events/' + calId);
        } catch (e) {
            toast.error('予定の取得リクエストに失敗しました。');
            return;
        }
        if (eventsResp.status === 500) {
            toast.error('サーバーエラー。');
            return;
        }
        if (eventsResp.status !== 200) {
            console.log('getFromServer failed', eventsResp);
            toast.error(`予定の取得に失敗しました。ステータス:${eventsResp.status}`);
            return;
        }

        const ges: Array<GCalEvent> = await eventsResp.json();
        if (!(ges instanceof Object)) {
            // on error return
            setInitialized(true);
            return;
        }

        console.log('gcal Events set');
        console.log(ges);
        SetGCalEvents(ges);
        setLogIn(true);
        setInitialized(true);
        const s: Array<string> = ges.map((ge: GCalEvent) => GCalEventToEventText(ge));
        setContentInternal(s.join('\n'));
    };

    const setContent = (e: any) => {
        const item: string = e.target.value;
        setContentInternal(item);
        setToStorage('/contents/1', item);
        // オフラインのときは、localStorageのを見られるだけ。
        setDirty(true);
        console.log('saved');
    };

    const displayStyledContent = (c: string) => {
        const parsedEvents = parse(c);
        const stylezedEvents = stylize(parsedEvents, nLines);
        return stylezedEvents;
    };

    useEffect(() => {
        // on mount
        initContent().then(() => {
            console.log('initialized');
        });
    }, []);

    // useEffect(() => {
    //     setInitialized(true);
    // }, [isLoggedIn]);

    const sendContentIfDirty = async () => {
        if (isDirty) {
            const toastId = toast.loading('保存中');
            const contentArr = content.trim().split(/\r\n|\n/);
            let beginYear: number;

            // beginYearが最初になければ設定し、contentの最初にyearSeparator(## 2022)を追加した上で処理を継続する。
            const pe = parseLine(contentArr[0]);
            if (!parseInt(pe.bY)) {
                // Time Separatorを追加する。
                // YearはLocalになってしまっている。
                // 関数の中でsetしているので、初回バグる?
                const d = new Date(Date.now() + AsiaTokyoTimeZoneOffset);
                beginYear = d.getUTCFullYear();
                const newContent = `## ${beginYear}\n` + content;
                setContentInternal(newContent);
                setToStorage('/contents/1', newContent);
                console.log('set beginYear and saved');
            } else {
                beginYear = parseInt(pe.bY);
            }

            // TODO: 予定が全く存在しない場合、サンプル予定を入れる。

            const ges = ContentsToGCalEvents(contentArr, beginYear);
            if (ges === null) {
                toast.error('保存できませんでした。Googleイベントへの変換に失敗しました。');
                toast.dismiss(toastId);
                enableSaveButton(true);
                return;
            }
            console.log(gCalEvents, ges, pe, contentArr);
            const diff = GCalDiff(gCalEvents, ges);
            // TODO: 差分検知して、サーバーに送信。
            if (diff === null) {
                console.log('error GCalDiff returns null');
                toast.error('保存できませんでした。差分計算に失敗しました。');
                toast.dismiss(toastId);
                enableSaveButton(true);
                return;
            }

            const { up: up, down: down, modify: modify } = diff;
            if (calId === '') {
                toast.error('カレンダーIDがありません。\n再読み込みしてください。');
                toast.dismiss(toastId);
                enableSaveButton(true);
                return;
            }
            if (up.length === 0 && down.length === 0 && modify.length === 0) {
                console.log('変更がないためスキップ。');
            } else {
                let editResponse: Response;
                try {
                    editResponse = await fetch('/api/edit/' + calId, { method: 'POST', body: JSON.stringify({ Up: up, Down: down, Modify: modify }) });
                } catch (e) {
                    toast.error('保存リクエストに失敗しました。');
                    return;
                }
                if (editResponse.status !== 200) {
                    toast.error(`予定の保存に失敗しました。ステータス:${editResponse.status}`);
                    toast.dismiss(toastId);
                    enableSaveButton(true);
                    return;
                }
                const editResult: Array<GCalEvent> = await editResponse.json();
                console.log(editResult);
                SetGCalEvents(editResult);
            }
            setDirty(false);
            toast.dismiss(toastId);
            enableSaveButton(false);

            // サーバーから来てsetしたcontentsと、今localにあるcontentsを比較？ contentsは同じ可能性もある。
            // 差分をcreate, delete, updateで比較?
            // privatePropertyは、差分管理に使う。
            // const data = await postToServer('/contents/1', { Content: content });
            // console.log(data);
        }
    };

    return (
        <div style={{ overflow: 'hidden', textAlign: 'left', height: '100%', margin: '10px' }}>
            {(!isLoggedIn || !isEdit) && (
                <div
                    style={{ whiteSpace: 'pre-wrap', width: '100%', height: '100%', resize: 'none', fontSize: fontSize, lineHeight: lineHeight, margin: 0 }}
                    onClick={() => {
                        if (isInitialized && !isLoggedIn) {
                            console.log('tapped without login', isInitialized, isLoggedIn);
                            toast.error('ログインしてください');
                            navigate('/settings');
                        }
                        setEdit(true);
                    }}
                >
                    {displayStyledContent(content)}
                </div>
            )}
            {isLoggedIn && isEdit && (
                <textarea
                    style={{ whiteSpace: 'pre-wrap', width: '100%', height: '100%', resize: 'none', fontSize: fontSize, lineHeight: lineHeight, margin: 0 }}
                    rows={nLines}
                    autoFocus={true}
                    value={content}
                    onFocus={(e) => {
                        // Blueが外れた時に保存ボタンが動作するので、Focusしたときは、保存ボタンの挙動は無効化して良い。
                        console.log('disabled save button');
                        enableSaveButton(false);
                    }}
                    onChange={(e) => {
                        setContent(e);
                    }}
                    onBlur={async () => {
                        setEdit(false);
                        await sendContentIfDirty();
                    }}
                ></textarea>
            )}
            {isInitialized && isLoggedIn && (
                <Button
                    variant={isDirty ? 'contained' : 'outlined'}
                    sx={{ textTransform: 'none', position: 'absolute', right: '10px', bottom: '66px' }}
                    onClick={async () => {
                        // ボタンを押すと、textareaのonBlurが呼ばれるので、ボタン自体にアクションは要らない。
                        // Blurが外れてリクエスト失敗した場合のみ、ボタンが動作する。
                        if (saveButtonEnabled) {
                            await sendContentIfDirty();
                        }
                    }}
                >
                    {isDirty ? '保存' : '保存済'}
                </Button>
            )}
            {isInitialized && !isLoggedIn && (
                <Button variant="outlined" sx={{ textTransform: 'none', position: 'absolute', right: '10px', bottom: '66px' }}>
                    ログイン失敗
                </Button>
            )}
            {!isInitialized && (
                <Button variant="outlined" sx={{ textTransform: 'none', position: 'absolute', right: '10px', bottom: '66px' }}>
                    ログイン中
                </Button>
            )}
        </div>
    );
}
