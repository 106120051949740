import { ParsedEvent, IsYearSeparator } from '../entities/Event';

export const stylize = (arr: Array<ParsedEvent>, nLines: number) => {
    const styled: Array<JSX.Element> = [];
    for (let i = 0; i < arr.length; i++) {
        const x = arr[i];
        if (IsYearSeparator(x)) {
            styled.push(
                <div key={i}>
                    {/* fontWeight: 'bold',  */}
                    <p style={{ display: 'inline', color: 'mediumblue' }}>{x.contents} </p>
                </div>
            );
        } else {
            styled.push(
                <div key={i}>
                    {x.date !== '' && <p style={{ display: 'inline', color: 'mediumblue' }}>{x.date} </p>}
                    {x.time !== '' && <p style={{ display: 'inline', color: 'mediumblue' }}>{x.time} </p>}
                    <p style={{ display: 'inline', margin: 0 }}>{x.contents + '　'}</p>
                </div>
            );
        }
    }

    // pad
    for (let i = arr.length; i < nLines; i++) {
        styled.push(
            <div key={i}>
                <p style={{ display: 'inline', margin: 0 }}>{'　'}</p>
            </div>
        );
    }
    return styled;
};
