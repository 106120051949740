import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import toast from 'react-hot-toast';

function GoogleLogin() {
    fetch('/api/login/google')
        .then((resp) => resp.json())
        .then((data) => {
            const url = data['redirectURL'];
            window.location.href = url;
        })
        .catch((e) => {
            toast.error('ログイン中にエラーが発生しました。');
        });
}

type GoogleLoginButtonProps = {
    sx: object;
};

export function GoogleLoginButton(props: GoogleLoginButtonProps) {
    const [loginBtnMsg, setLoginBtnMsg] = useState('Googleでログイン');
    const onClickLoginButton = (e: any) => {
        setLoginBtnMsg('ログイン中');
        GoogleLogin();
    };
    return (
        <Button variant="contained" sx={props.sx} onClick={onClickLoginButton}>
            {loginBtnMsg}
        </Button>
    );
}
