// http://localhost:4000/google_login_callback?state=FgoilA0U1WxNeW1gdgUVDsEWJ77aX7tLFJ84qYU6UrN8ctecwZt5S4zjhD0tXRTm&code=4/0AX4XfWjdCMvt9ok3iQiQT85r6BUOO1MuGb2cAEvIEXTfbUTiTvvhhYB17vFkhcYimwAznQ&scope=https://www.googleapis.com/auth/calendar
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const LoginCallbackGoogle = () => {
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const state = params.get('state');
        const code = params.get('code');
        const scope = params.get('scope');
        fetch('/api/callback/google', { method: 'POST', body: JSON.stringify({ State: state, Code: code, Scope: scope }) })
            .then((resp) => resp.json())
            .then((data) => {
                window.location.href = '/';
            });
    }, []);
    return <div></div>;
};
